import React from 'react'
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import { useNavigate } from 'react-router-dom'

function CreateUser() {
    
    const authHeader = useAuthHeader();
    axios.defaults.headers.common = {'Authorization': `${authHeader}`};
    let navigate = useNavigate();

    const notify = () => toast("Se creó el usuario correctamente!");

    const initialValues = {
        email: "",
        password:"",        
    };
    const validationSchema = Yup.object().shape({
        email: Yup.string().min(3).max(200).required(),
        password: Yup.string().min(8).max(18).required(),        
    });
    const onSubmit = (data) => {
        console.log(data);
        axios.post(`${process.env.REACT_APP_API_URL}/users/register`,data).then((response) => {
            console.log("user saved:"+response);
            notify();
            //wait 1000ms
            setTimeout(() => {
                navigate('/');
            }, 300);
          })
    };

  return (
    <div className="container mt-5 pt-5">        
        <Formik  initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            <Form>
                <input id="inputCreatePost" type="hidden" name="id" value="0" />
                <div className='form-group'>
                    <label>Usuario</label>
                    <ErrorMessage name="email" component="span" />
                    <Field id="inputCreatePost" className='form-control' name="email" autocomplete="off"  placeholder="Email Usuario" />
                </div>
                <div className='form-group'>
                    <label>Password</label>
                    <ErrorMessage name="password" component="span" />
                    <Field id="inputCreatePost" className='form-control' name="password" type="password" autocomplete="off" placeholder="Contraseña de al menos 8 caracteres" />
                    
                </div>                
                <button className='btn btn-primary'>Create User</button>
                <ToastContainer />
            </Form>
        </Formik>
    </div>
  )
}

export default CreateUser