import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate,useParams, useLocation } from 'react-router-dom'
import moment from 'moment';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

function Messages() {
    const [messagesList, setListOfMessages] = useState([]);
    const authHeader = useAuthHeader();
    axios.defaults.headers.common = {'Authorization': `${authHeader}`};
    const [phone,setPhone] = useState({});    
    const [chat,setChat] = useState({});
    const navigate = useNavigate();
    let { chatId } = useParams();
    const location = useLocation();   
    const getClass = (fromMe) => {
        // if (fromMe) return 'table-primary text-left mine';
        // else return 'table-success text-right theirs';       
        if (fromMe) return 'text-left mine';
        else return 'text-right theirs';       
    }
    const goBack = () => {
        navigate(-1);
    }
    const getObjectBody = (value) => {
      if(value.type==='location'){
        let location = JSON.parse(value.object);
        return (<a href={`https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`} target='_blank' rel="noreferrer"><img src={`data:image/jpeg;base64,${value.message}`} alt={value.message} /></a>);
      }
      if(value.object===undefined || value.object===null || value.object===''){
        return (<span>{value.message}</span>);
      }
      if(value.object.indexOf(';base64,') === -1){
        return (<img src={`data:image/jpeg;base64,${value.object}`} alt={value.message} />);
      }
      else{
        if(value.type==='image'){
          return (<img src={`${value.object}`} alt={value.message} />);
        }
        if(value.type==='sticker'){
          return (<img src={`${value.object}`} alt={value.message} width="150" />);
        }
        const mimeType = value.object.split(',')[0].split(':')[1].split(';')[0];        
        return (
          <div>
            <object
              data={value.object}
              type={mimeType} // Specify the type of the document
              width="100%"              
            >
              {/* If the browser doesn't support displaying PDFs, provide a download link */}
              <p>Sorry, your browser doesn't support embedded files.</p>
              <a href={value.object}>Download document</a>
            </object>
          </div>
        );
      }
      

    }
    useEffect( () => {
        const passedData = location.state?.passedData;
        setPhone(passedData.phone);
        setChat(passedData.chat);
        axios.get(`${process.env.REACT_APP_API_URL}/messages/${chatId}`).then((response) => {
          console.log(response);
          setListOfMessages(response.data);    
        })    
    }, [chatId,location]);
      
  return (
    <div className='container mt-5'>
       <button className='btn btn-light' onClick={goBack}><span className="material-icons">arrow_back</span></button><h1 className='text-left'>List of Messages for {phone.client} - {phone.phoneNumber}</h1>
        <h2 className='text-left'>Chat: {chat.chatName}</h2>
      <table id='chat' className='table table-sm'>
        <thead className='thead-dark'>
          <tr>
            <th className='col-sm-6'>Enviados</th>  
            {/* <th>Fecha y Hora</th>           */}
            <th className='col-sm-6'>Recibidos</th>
          </tr>
        </thead>
        <tbody>
      
      {messagesList.map((value,key) => {
        return <tr key={key} className={getClass(value.fromMe)}>
          <td>                        
            {value.type!=='chat' && !value.fromMe && getObjectBody(value)}
            {value.type==='chat' && !value.fromMe && <span>{value.message}</span> }
            {!value.fromMe && <div className='timestamp'>{moment(value.timestamp).format('DD-MM-YY HH:mm a') }</div>}
          </td>                    
          <td>                        
            {value.type!=='chat' && value.fromMe && getObjectBody(value)}
            {value.type==='chat' && value.fromMe && <span>{value.message}</span>}
            {value.fromMe && <div className='timestamp'>{moment(value.timestamp).format('DD-MM-YY HH:mm a') }</div>}
          </td>           
        </tr>
        
      })}
        </tbody>
      </table>
      <hr />
    </div>
  )
}

export default Messages