import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

function EditPhones() {
    const authHeader = useAuthHeader();
    axios.defaults.headers.common = {'Authorization': `${authHeader}`};
    const location = useLocation();
    let [action,setAction] = useState("Edit");
    let [phone,setPhone] = useState({
        id:0,
        client: "",
        phoneNumber:"",
        wid:"",
        status:true,
    });
    const notify = () => toast("Se guardó el teléfono correctamente!");                
    
    let navigate = useNavigate();
    let { id } = useParams();

    const handleNavigateBack = () => {        
        navigate(-1);
    };
    
    const onSubmit = (e) => {        
        e.preventDefault()
        console.log(phone);
        axios.post(`${process.env.REACT_APP_API_URL}/phones/phone`,phone).then((response) => {
            console.log("phone saved:"+ response);
            notify();
            
          })
    };    
    useEffect( () => {          
        setAction("Update");
        const passedData = location.state?.passedData.phone;      
        if (passedData == null ){                    
            axios.get(`${process.env.REACT_APP_API_URL}/phones/phone/${id}`).then((response) => {
                console.log(response);                
                setPhone(response.data);                                      
            })        
        }
        else{
            setPhone(passedData);                                 
        }
        
        
    }, [location.state.passedData.phone, id]);	

  return (
    <div className="container mt-5">
            <button className='btn btn-small btn-light' onClick={handleNavigateBack}><span className="material-icons">arrow_back</span></button>
            <h1 className='text-left'>{action} Phone</h1>                
            <form onSubmit={onSubmit}>
                <input id="inputCreatePost" type="hidden" name="id" value={phone.id} onChange={e => setPhone( {...phone,id:e.target.value} )} />
                <div className='form-group'>
                    <label>Cliente o Usuario</label>
                    {/* <ErrorMessage name="client" component="span" /> */}
                    <input id="inputCreatePost" className='form-control' name="client" autoComplete="off"  placeholder="Nombre Usuario" value={phone.client} onChange={e => setPhone( {...phone,client:e.target.value} )} />
                    
      
                </div>
                <div className='form-group'>
                    <label>Número de teléfono</label>
                    {/* <ErrorMessage name="phoneNumber" component="span" /> */}
                    <input id="inputCreatePost" className='form-control' name="phoneNumber" autoComplete="off" placeholder="Número sin + con codigo de país ej. 50688884444" value={phone.phoneNumber} onChange={e => setPhone( {...phone,phoneNumber:e.target.value} )} />
                    
                </div>
                <div className='form-group'>
                    <label className='form-check-label'>Activo</label>
                    {/* <ErrorMessage name="status" component="span" /> */}
                    <input className='form-check-input' type="checkbox" id="inputCreatePost" name="status" checked={phone.status} onChange={e => setPhone( {...phone,status:e.target.checked} )}/>                    
                </div>
                <button type='submit' className='btn btn-primary'>{action} Phone</button>
                <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"                
                 />
            </form>        
    </div>
  )
}

export default EditPhones