import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

function Users() {  
    const [listOfUsers, setListOfUsers] = useState([]);
    let navigate = useNavigate();
    const authHeader = useAuthHeader();
    axios.defaults.headers.common = {'Authorization': `${authHeader}`};
    const notify = (message) => toast(message);  

    const handleEdit = (user) => {
      const dataToPass = { user: user };
      navigate(`/edituser/${user.id}`, { state: { passedData: dataToPass } });
    };
    const handleDelete = (user) => { 
        //const dataToPass = { id: user.id, email: user.email};
        //console.log("deleting user:"+user.id);
        axios.delete(`${process.env.REACT_APP_API_URL}/users/${user.id}`).then((response) => { 
            notify('User deleted successfully');         
            const updatedList = listOfUsers.filter(savedUser => savedUser.id !== user.id);
            // Update the state with the new array
            setListOfUsers(updatedList);
        })
        .catch((error) => {
          console.log(error);
        });
      };

    useEffect( () => {
        axios.get(`${process.env.REACT_APP_API_URL}/users`).then((response) => {          
          setListOfUsers(response.data);    
        })    
      }, []);
  return (    
    <div className='container mt-5'>
      <h1>List of Users</h1>
      <table className='table table-striped'>
        <thead>          
          <tr>
            <th>Id</th>
            <th>Email</th>            
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
      
      {listOfUsers.map((value,key) => {
        return <tr key={key}>
          <td>{value.id}</td>
          <td>{value.email}</td>          
          <td>            
            <button className="btn btn-sm btn-success"
                onClick={() => {handleEdit(value)}}>                            
                <i className="material-icons">edit</i>
            </button>
            <button className="btn btn-sm btn-danger"
                onClick={() => {handleDelete(value)}}>                            
                <i className="material-icons">delete_outline</i>
            </button>
          </td>
        </tr>
        
      })}
        </tbody>
      </table>
      <hr />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"                
      />
    </div>
  )
}

export default Users