import React, { useState } from "react";
//import { Form, Button, Alert } from "react-bootstrap";
import "./login.css";
import BackgroundImage from "../images/background.png";
import Logo from "../images/icon.png";
import axios from "axios";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const signIn = useSignIn();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const notify = (message) => toast(message);  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    /*await delay(500);
    console.log(`Username :${inputUsername}, Password :${inputPassword}`);*/
    const loginData = {
      username: inputUsername,
      password: inputPassword,
    };
    try {
      const loginresp = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login/`,loginData)
      if(loginresp.status === 200){
        let user = loginresp.data.user;
        signIn({
          auth:{
            token: loginresp.data.authToken,
            //tokenType: "Bearer",
            expiresIn: 3600,
          },          
          userState: { id:user.id, username: inputUsername },          
        });
        notify("Ingreso Exitoso!");
        setTimeout(() => {
          navigate('/');
        }, 500);

      }
      else{
        notify("Falló el ingreso: "+loginresp.data.message);
      }      
    } catch (error) {
      if(error.response && error.response.data){
        let responseData = error.response.data;      
        notify("Falló el ingreso: "+responseData.message);
      }
      else{
        notify("Sucedió un error al ingresar: "+error.message);
        console.error('Error:', error);
      }
      
    }
    finally{
      setLoading(false);
    }
    
  };

  const handlePassword = () => {};

  /*function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }*/

  return (
    <div className="sign-in__wrapper" style={{ backgroundImage: `url(${BackgroundImage})` }}>
      {/* Overlay */}
      <div className="sign-in__backdrop"></div>
      {/* Form */}
      <form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
        {/* Header */}
        <img
            className="img-fluid mx-auto d-block mb-2"
            src={Logo}
            alt="logo"
        />
        
        <div className="h4 mb-2 text-center">Sign In</div>        
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            className="form-control mb-2"
            type="text"
            id="username"
            name="username"
            value={inputUsername}
            placeholder="Username"
            onChange={(e) => setInputUsername(e.target.value)}
            aria-describedby="emailHelp"
            required
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="password">Password</label>
          <input
            className="form-control"
            type="password"
            id="password"
            name="password"
            value={inputPassword}
            placeholder="Password"
            onChange={(e) => setInputPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-check">
            <label className="mb-2" htmlFor="rememberMe">
                <input name="rememberMe" id="rememberMe" type="checkbox"/> Remember me
            </label>
        </div>
        {!loading ? (
          <button className="btn btn-primary btn-lg w-100" type="submit">
            Log In
          </button>
        ) : (
          <button className="btn btn-secondary btn-lg w-100" type="button" disabled>
            Logging In...
          </button>
        )}
        <div className="d-grid justify-content-end">
          <button
            className="btn btn-sm btn-link"            
            onClick={handlePassword}
          >
            Forgot password?
          </button>
        </div>
      </form>
      {/* Footer */}
      <div className="w-100 mb-2 position-absolute bottom-0 start-50 translate-middle-x text-white text-center">
        <small>© 2024 Bammboo Tech Studio</small>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"                
      />
    </div>
  );
};

export default Login;
