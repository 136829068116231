import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSignOut from "react-auth-kit/hooks/useSignOut";
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

const NavMenu = () => {    
    const authHeader = useAuthHeader();
    axios.defaults.headers.common = {'Authorization': `${authHeader}`};
    const navigate = useNavigate();  
    const signOut = useSignOut();

    const logout = async () => {
      await axios.delete(`${process.env.REACT_APP_API_URL}/users/logout/`).then((response) => {
        console.log("logout response successfull:"+response);        
        signOut();
        navigate('/login');
        
      })
      .catch((error) => { 
        signOut();
        console.log("Failed to sign out on server - error:"+error);
        navigate('/login');
        
      });

        
    }
  return (
    <div>          
        <ul className="navbar-nav navbar-expand-lg">
            <li className="nav-item">
                <Link className="mx-2 text-uppercase btn btn-light" to="/">Home</Link>
            </li>
            <li className="nav-item">
                <Link className="mx-2 text-uppercase btn btn-light" to="/createphone">Create New Phone</Link>
            </li>
            <li className="nav-item">
                <Link className="mx-2 text-uppercase btn btn-light" to="/createuser">Create New User</Link>
            </li>
            <li className="nav-item">
                <Link className="mx-2 text-uppercase btn btn-light" to="/users">Users</Link>
            </li>
            <li className="nav-item">
              <button className="mx-2 btn btn-sm btn-link text-uppercase" onClick={logout}>Logout</button>
            </li>
        </ul>
        
        
    </div>     
  );
};

export default NavMenu;
