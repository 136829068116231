// MenuContext.js
import React, { createContext, useState, useContext } from 'react';

const MenuContext = createContext();

const MenuProvider = ({ children }) => {
  const [isMenuVisible, setMenuVisible] = useState(true);

  const toggleMenu = () => {
    setMenuVisible((prev) => !prev);
  };
  const hideMenu = () => {
    setMenuVisible(false);
  }
  return (
    <MenuContext.Provider value={{ isMenuVisible, toggleMenu, hideMenu,setMenuVisible }}>
      {children}
    </MenuContext.Provider>
  );
};

const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('useMenuContext must be used within a MenuProvider');
  }
  return context;
};

export { MenuProvider, useMenuContext };
