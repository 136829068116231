import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'  
import axios from 'axios';
import QRCode from "react-qr-code";
import io from "socket.io-client";
//import { useMenuContext } from '../components/MenuContext';

const socket = io.connect(`${process.env.REACT_APP_API_URL}`, {});

function PhoneQR() {    
    const { pid } = useParams();    
    const [phone,setPhone] = useState({});
    const [qrVisible, setQrVisible] = useState(false);    
    const [clientStatus,setClientStatus] = useState("");
    const [qrCode,setQrCode] = useState("");        
    const [isConnected,setConnected] = useState(true);            
    
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/phones/phone/${pid}`).then((response) => {                      
            setPhone(response.data);
        });        
    }, [pid]);

    useEffect(() => {        
        if(phone.status) socket.connect();
        else {
            setConnected(false);
            setClientStatus("Teléfono inactivo");            
        }
        // axios.get(`${process.env.REACT_APP_API_URL}/phones/phone/${pid}`).then((response) => {                      
        //     setPhone(response.data);
        // });        

        function onConnect() {            
            setConnected(true);
            if(phone.status) socket.emit("connected", phone);           
        }
        function onDisconnect() {
            console.log("Disconnected!");            
            setConnected(false);
            setQrVisible(false);
        }
        
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
                
      
        return () => {
            socket.disconnect();
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);           
        };

      }, [phone]); 

      useEffect(() => {
        function onQr(data) {
            setClientStatus("Waiting to scan QR...");            
            setQrVisible(true);            
            setQrCode(data.qr);              
        }
        function onReady(data) {                        
            console.log(data);
            setClientStatus("Ready!");
        }

        function onClientState(data){
            let {message} = data;	
            console.log(data);
            setClientStatus(message);
        }

        function onAuthenticated(data) {
            console.log(data);
            setQrVisible(false);            
            setClientStatus("Authenticated on Whatsapp!");
        }

        function onChangedState(data){
            let {message} = data;	
            console.log(data);
            setClientStatus(message);
        }
        socket.on("qr", onQr);    
        socket.on("ready",onReady);
        socket.on("authenticated",onAuthenticated);
        socket.on("changed_state",onChangedState);
        socket.on("client_state",onClientState);
        return () => {          
            socket.off("qr", onQr);    
            socket.off("ready",onReady);
            socket.off("changed_state",onChangedState);
            socket.off("client_state",onClientState);
            socket.off("authenticated",onAuthenticated); 
        };    
      }, []);
      
      
  return (
    <div className='mt-5 container'>        
        <table className='table table-striped'>
            <caption className="d-flex justify-content-between align-items-center">
                <span>Detalles del teléfono</span>                
            </caption>
            <tbody>                
                <tr>
                    <td>Client</td><td>{phone.client}</td>                    
                </tr>
                <tr>
                    <td>Number</td><td>{phone.phoneNumber}</td>
                </tr>                
                <tr>
                    <td>Activo</td><td>{phone.status ? 'activo' : 'inactivo'}</td>
                </tr>                
                <tr>
                    <td>Status Whatsapp</td><td>{clientStatus ? <span>  {clientStatus} </span> : ''}</td>
                </tr>
                <tr>
                    <td>Conexión Backend</td><td>{isConnected ? <div class="led-light led-success"></div> : <div class="led-light led-danger"></div>}</td>
                </tr>

            </tbody>
        </table>
        <div className='container my-3'>            
                {qrVisible ? <figure className="figure"><QRCode value={qrCode}></QRCode></figure>  : null}                            
        </div>
        <hr />        
    </div>
    
  )
}

export default PhoneQR