import React from 'react';

const CopyToClipboardButton = ({ textToCopy,label,cssClass }) => {
  const copyToClipboard = async () => {
    try {
      // Request permission to write to the clipboard
      await navigator.clipboard.writeText(textToCopy);
      console.log(`${label} copied to clipboard:`, textToCopy);
    } catch (err) {
      console.error('Unable to copy text to clipboard', err);
    }
  };

  return (
    <div>
      <button data-bs-toggle="tooltip" data-bs-placement="right" title="Click to copy" className={`btn btn-sm ${cssClass}`} onClick={copyToClipboard}>{label}</button>      
    </div>
  );
};

export default CopyToClipboardButton;
