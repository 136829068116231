import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import 'react-toastify/dist/ReactToastify.css';

function Home() {
  /*const { setMenuVisible } = useMenuContext();
  setMenuVisible(true);*/
    const authHeader = useAuthHeader();
    axios.defaults.headers.common = {'Authorization': `${authHeader}`};
    const [listOfPhones, setListOfPhones] = useState([]);
    let navigate = useNavigate();
    const notify = (message) => toast(message);

    const handleNavigate = (phone) => {
      const dataToPass = { phone: phone };      
      navigate(`/phone/${phone.id}`, { state: { passedData: dataToPass } });
    };

    const handleEdit = (phone) => {
      const dataToPass = { phone: phone };
      navigate(`/editphone/${phone.id}`, { state: { passedData: dataToPass } });
  };

  const handleDelete = (phone) => { 
    //const dataToPass = { id: user.id, email: user.email};
    //console.log("deleting user:"+user.id);
    axios.delete(`${process.env.REACT_APP_API_URL}/phones/${phone.id}`).then((response) => {          
        notify('Phone deleted successfully');
        const updatedList = listOfPhones.filter(savedPhone => savedPhone.id !== phone.id);
        // Update the state with the new array
        setListOfPhones(updatedList);
    })
    .catch((error) => {
      console.log(error);
    });
  };

    useEffect( () => {
        axios.get(`${process.env.REACT_APP_API_URL}/phones` ).then((response) => {          
          setListOfPhones(response.data);    
        })    
      }, []);
  return (    
    <div className='container mt-5'>
      <h1>List of phones</h1>
      <table className='table table-striped'>
        <thead>          
          <tr>
            <th>Client</th>
            <th>Phone</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
      
      {listOfPhones.map((value,key) => {
        return <tr key={key}>
          <td>{value.client}</td>
          <td>{value.phoneNumber}</td>
          <td>{value.status ? 'activo' : 'inactivo'}</td>          
          <td>            
            <button className="btn btn-sm btn-primary"
              onClick={() => {handleNavigate(value)}}>
                <i className="material-icons">preview</i>
            </button>
            <button className="btn btn-sm btn-success"
                onClick={() => {handleEdit(value)}}>                            
                <i className="material-icons">edit</i>
            </button>
            <button className="btn btn-sm btn-danger"
                onClick={() => {handleDelete(value)}}>                            
                <i className="material-icons">delete_outline</i>
            </button>
          </td>
        </tr>
        
      })}
        </tbody>
      </table>
      <hr />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"                
      />    
    </div>
  )
}

export default Home