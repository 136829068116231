import React from 'react'
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

function CreatePhones() {
    const authHeader = useAuthHeader();    
    axios.defaults.headers.common = {'Authorization': `${authHeader}`};
    let navigate = useNavigate();

    const notify = () => toast("Se creó el teléfono correctamente!");

    const initialValues = {
        client: "",
        phoneNumber:"",
        wid:"",
        status:true,
    };
    const validationSchema = Yup.object().shape({
        client: Yup.string().min(3).max(200).required(),
        phoneNumber: Yup.number().required(),        
        status:Yup.bool()
    });
    const onSubmit = (data) => {
        console.log(data);
        axios.post(`${process.env.REACT_APP_API_URL}/phones/phone`,data).then((response) => {
            console.log("phone saved:"+response);
            notify();
            //wait 1000ms
            setTimeout(() => {
                navigate('/');
            }, 300);
          })
    };

  return (
    <div className="container mt-5 pt5">        
        <Formik  initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            <Form>
                <input id="inputCreatePost" type="hidden" name="id" value="0" />
                <div className='form-group'>
                    <label>Cliente o Usuario</label>
                    <ErrorMessage name="client" component="span" />
                    <Field id="inputCreatePost" className='form-control' name="client" autocomplete="off"  placeholder="Nombre Usuario" />
                </div>
                <div className='form-group'>
                    <label>Número de teléfono</label>
                    <ErrorMessage name="phoneNumber" component="span" />
                    <Field id="inputCreatePost" className='form-control' name="phoneNumber" autocomplete="off" placeholder="Número sin + con codigo de país ej. 50688884444" />
                    
                </div>
                <div className='form-group'>
                    <label className='form-check-label'>Activo</label>
                    <ErrorMessage name="status" component="span" />
                    <Field className='form-check-input' type="checkbox" id="inputCreatePost" name="status" />                    
                </div>
                <button className='btn btn-primary'>Create Phone</button>
                <ToastContainer />
            </Form>
        </Formik>
    </div>
  )
}

export default CreatePhones