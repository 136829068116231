import React, {useEffect, useState} from 'react';
import { useNavigate,useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import QRCode from "react-qr-code";
import io from "socket.io-client";
import CopyToClipboardButton from '../components/CopyToClipboardButton';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const socket = io.connect(`${process.env.REACT_APP_API_URL}`, {});

function Phone() {
    let navigate = useNavigate();
    const authHeader = useAuthHeader();
    axios.defaults.headers.common = {'Authorization': `${authHeader}`};
    let { pid } = useParams();
    const location = useLocation();   
    const [phone,setPhone] = useState({});
    const [qrVisible, setQrVisible] = useState(false);
    const [token,setToken] = useState("");
    const [chats,setChats] = useState([]);
    const [clientStatus,setClientStatus] = useState("");
    const [isSpinning, setSpinning] = useState(false);
    const [qrCode,setQrCode] = useState("");        
    const [isConnected,setConnected] = useState(true);        
    const [receivedId, setReceivedId] = useState("");
    const currentUrl = window.location.origin;
    
    //const [media,setImg] = useState("");    

    const notify = (message) => toast(message);

    /***Navigation ***/
    const handleEdit = (phone) => {
        const dataToPass = { phone: phone };
        navigate(`/editphone/${phone.id}`, { state: { passedData: dataToPass } });
    };
    const handleToken = async () => {
        setSpinning((prev) => !prev);        
        axios.get(`${process.env.REACT_APP_API_URL}/phones/phone/token/${phone.id}`).then((response) => {
            setToken(response.data.token);            
            //setPhone( {...phone,token:response.data.token} );
            notify("Token generado correctamente!");           
        }).catch((error) => {
            console.log(error);
            notify("Error al generar token");           
        }).finally(() => {
            setSpinning((prev) => !prev);
        });
    };
    const handleNavigate = (chat) => {
        const dataToPass = { phone: phone, chat: chat };
        navigate(`/messages/${chat.id}`, { state: { passedData: dataToPass } });
    };
    const handleNavigateBack = () => {
        navigate(-1);
    }
    const getChats = (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/chats/${id}`).then((response) => {
            setChats(response.data);
        });
    }
    /***Navigation ***/

    useEffect(() => {
        const passedData = location.state.passedData;        
        if (passedData == null || passedData.phone == null) axios.get(`${process.env.REACT_APP_API_URL}/phones/phone/${pid}`).then((response) => {          
            setPhone(response.data);
            setToken(response.data.token);
            getChats(response.data.id);
        });
        else{
            setPhone(passedData.phone);
            setToken(passedData.phone.token);
            getChats(passedData.phone.id);            
        }
    }, [location,pid]);


    useEffect(() => {                        
        if(phone.status) socket.connect();
        else {
            setConnected(false);
            setClientStatus("Teléfono inactivo");
        }        

        function onConnect() {
            //console.log(`Phone ${phone.phoneNumber} Connected!`);            
            setConnected(true);
            if(phone.status) socket.emit("connected", phone);           
        }
        function onDisconnect() {
            console.log("Disconnected!");            
            setConnected(false);
            setQrVisible(false);
        } 
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);        
      
        return () => {
            socket.disconnect();
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
        };

      }, [phone]);
    
    
    useEffect( () => {
        function onQr(data) {
            setClientStatus("Waiting to scan QR...");
            setReceivedId(data.id);            
            setQrVisible(true);            
            setQrCode(data.qr);              
        }
        function onReady(data) {                        
            console.log(data);
            setClientStatus("Ready!");
        }

        function onClientState(data){
            let {message} = data;	
            console.log(data);
            setClientStatus(message);
        }

        function onAuthenticated(data) {
            console.log(data);
            setQrVisible(false);            
            setClientStatus("Authenticated on Whatsapp!");
        }

        function onChangedState(data){
            let {message} = data;	
            console.log(data);
            setClientStatus(message);
        }

        socket.on("qr", onQr);    
        socket.on("ready",onReady);
        socket.on("authenticated",onAuthenticated);
        socket.on("changed_state",onChangedState);
        socket.on("client_state",onClientState);
    
    return () => {             
        socket.off("qr", onQr);    
        socket.off("ready",onReady);
        socket.off("changed_state",onChangedState);
        socket.off("client_state",onClientState);
        socket.off("authenticated",onAuthenticated);        
      };

      }, [token]);
  return (
    <div className='mt-5 container'>        
        <table className='table table-striped'>
            <caption>
                Detalles del teléfono                
            </caption>
            <thead>
                <tr>
                    <th>Detalles del teléfono</th>
                    <th className='text-align-right'>
                        <div>
                            <button className="btn btn-sm btn-success"
                                onClick={() => {handleEdit(phone)}}>                            
                                <i className="material-icons">edit</i>
                            </button>
                            <button className="btn btn-sm btn-danger"
                                onClick={() => {handleNavigateBack()}}>                            
                                <i className="material-icons">arrow_back</i>
                            </button>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Id</td><td>{phone.id}</td>                    
                </tr>
                    <td>Client</td><td>{phone.client}</td>                    
                <tr>
                    <td>Number</td><td>{phone.phoneNumber}</td>
                </tr>                
                <tr>
                    <td>Activo</td><td>{phone.status ? <span>Activo<i className="material-icons">verified</i></span> : <span>Inactivo<i className="material-icons">highlight_off</i></span>}</td>
                </tr>                
                <tr>
                    <td>Phone Token</td><td>{token && token !== "" ? <CopyToClipboardButton  cssClass={''} textToCopy={`${token}`} label={`${token.slice(0,25)}...`} /> : '...'}<span className={`material-icons hand-pointer ${isSpinning ? 'spin' : ''}`} onClick={handleToken}>autorenew</span>refresh token</td>
                </tr>
                <tr>
                    <td>Status Whatsapp</td><td>{clientStatus ? <span>  {clientStatus} </span> : ''}</td>
                </tr>
                <tr>
                    <td>Conexión Backend</td><td>{isConnected ? <div className="led-light led-success"></div> : <div className="led-light led-danger"></div>}</td>
                </tr>

            </tbody>
        </table>
        <CopyToClipboardButton cssClass={'btn-warning'} textToCopy={`${currentUrl}/phoneQr/${phone.id}`} label={'Copy QR Only Route'} />
        <hr />
        <div className='container my-3'>            
                {qrVisible ? <figure className="figure"><QRCode value={qrCode}></QRCode><figcaption className="figure-caption">Qr for ID: {receivedId}</figcaption></figure>  : null}                            
        </div>
        <hr />
        <div>
            <table className='table table-striped'>
                <caption>Chats</caption>
                <thead>
                    <tr>
                        <th>Chat</th>
                        <th>Tipo</th>                        
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                {chats.map((value,key) => {
                    return <tr key={key}>
                        <td>{value.chatName}</td>
                        <td>{value.type} {value.type==="group" && <CopyToClipboardButton cssClass={'btn-link'} textToCopy={`${value.chatId}`} label={'Copy Group ID'} />}</td>
                        <td>                            
                            <button className="btn btn-sm btn-primary"
                            onClick={() => {handleNavigate(value)}}>
                                <i className="material-icons">preview</i>
                            </button>
                        </td>
                    </tr>                    
                })}                    
                </tbody>
            </table>
        </div>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"                
        />
    </div>
    
  )
}

export default Phone